<template>
  <article class="entry">
    <figure class="entry-media">
      <router-link :to="'/blog/' + blog.identifier">
        <img :src="blog.featured_image" alt="blog" />

        <!-- <img :src="blog.first_image" alt="blog" /> -->
        <!-- :width="blog.image[0].width"
					:height="blog.image[0].height"  -->
      </router-link>
    </figure>
    <div class="entry-body">
      <h3 class="entry-title">
        <router-link :to="'/blog/' + blog.identifier">{{
          blog.title
        }}</router-link>
      </h3>

      <div class="entry-meta text-capitalize">
        <router-link :to="blog.author.author_url"
          ><i class="icon-user"></i>{{ blog.author.name }}</router-link
        >
        <router-link to=""
          ><i class="icon-calendar"></i
          >{{ blogtDate(blog.creation_time) }}</router-link
        >
      </div>

      <div class="entry-content">
        <p v-html="stripHTMLTags(blog.short_filtered_content)"></p>
        <router-link :to="'/blog/' + blog.identifier">
          <button class="btn btn-dark">
            {{ $t("read_more") }}
          </button>
        </router-link>
      </div>
    </div>
  </article>
</template>
<script>
import { baseUrl } from "@/esf_weert_mobilesupplies/repositories/repository";

export default {
  props: {
    blog: Object,
  },
  data: function () {
    return {
      baseUrl: baseUrl,
    };
  },
  computed: {
    date: function () {
      let options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        timeZone: "UTC",
      };

      return new Date(this.blog.date).toLocaleString("en-us", options);
    },
    dateFormat() {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return options;
    },
  },
  methods: {
    blogtDate(date) {
      let formattedDate = new Date(date).toLocaleDateString(
        "nl-NL",
        this.dateFormat
      );
      return formattedDate;
    },
    stripHTMLTags(html) {
      return html.replace(/<\/?[^>]+(>|$)/g, "");
    },
  },
};
</script>
